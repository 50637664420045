
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import QrcodeVue from 'qrcode.vue';
import TwoFactorAuthenticationRepository from "@/api/repositories/TwoFactorAuthenticationRepository";

@Component({
  components: {
    CustomStepperComponent: () => import(
        /* webpackChunkName: "CustomStepperComponent" */
        '@/components/Misc/CustomStepper.component.vue'
        ),
    QrcodeVue
  }
})
export default class TwoFactorModalComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private onCodeEntered!: (code: string) => Promise<void>;

  @Prop({required: true})
  private description!: string;

  @Prop({required: true})
  private isActivatingTwoFactor!: boolean;

  private currentStep = 0;

  private maxSteps = 2;

  private headlines = [
    this.$t('EDIT_PROFILE.CHANGE_SECURITY.STEPS.CONNECT_AUTHENTICATOR'),
    this.$t('EDIT_PROFILE.CHANGE_SECURITY.STEPS.ENTER_CODE'),
  ];

  private async created() {
    await this.loadQRCode();
  }

  /**
   * stores the qr code data
   * @private
   */
  private qrCodeData!: string;

  /**
   * stores the input of the otp input field
   */
  private otpInput = "";

  /**
   * loading flag
   */
  private isLoading = false;

  private onNextClicked() {
    if(this.currentStep >= this.maxSteps -1) return;
    this.currentStep++;
  }

  private onBackPressed() {
    if(this.currentStep <= 0) return;
    this.currentStep--;
  }

  /**
   * flag that indicate if an error occurred while loading the qr code
   * @private
   */
  private errorLoadingQrCode: boolean = false;

  /**
   * is called when the code was entered, tries to do callback function
   * @private
   */
  private async onFinish() {
    try {
      this.isLoading = true;
      await new Promise((r) => setTimeout(r, 2000));
      await this.onCodeEntered(this.otpInput);
    } finally {
      this.isLoading = false;
      this.otpInput = "";
    }
  }

  /**
   * loads the qr code data from the api
   * @private
   */
  private async loadQRCode() {
    try {
      this.isLoading = true;
      const { data } = await TwoFactorAuthenticationRepository.getQRCode();
      this.qrCodeData = data;
    } catch(_) {
      this.errorLoadingQrCode = true;
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.FAILED_LOADING_TWO_FACTOR_QR_CODE');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * closes the modal, sends flag in event that indicate if something has updated and the news should reload
   * @param success
   * @private
   */
  private dismiss(success: boolean = false) {
    this.$emit('closeDialog', success);
  }
}
